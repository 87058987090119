import { CommonService } from 'src/app/shared/services/common.service';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { Location } from '@angular/common';
import { AuthenticationService } from '../../../core/services/auth.service';
import { AuthfakeauthenticationService } from '../../../core/services/authfake.service';
import { LanguageService } from '../../../core/services/language.service';
import { environment } from '../../../../environments/environment';
import { ApiService } from 'src/app/shared/services/api.service';
import { EventService } from 'src/app/core/services/event.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { User } from 'src/app/core/models/auth.models';
@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {
  private currentUserSubject: BehaviorSubject<User>;

  pageTitle: string
  element: any;
  configData: any;
  cookieValue;
  flagvalue;
  countryName;
  valueset: string;
  pageSize = 10;
  pageIndex = 1;
  totalCount = 2;
  refreshToken = JSON.parse(localStorage.getItem('currentUser')).refreshToken;
  clientId = JSON.parse(localStorage.getItem('currentUser')).id;
  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'Thailand', flag: 'assets/images/flags/tha.jpeg', lang: 'th' },
    { text: 'China', flag: 'assets/images/flags/cn.png', lang: 'cn' },
  ];
  data: any;
  role = JSON.parse(localStorage.getItem('currentUser')).role;

  listData: any;
  listWorkspace: any[];
  nameWorkspace = localStorage.getItem('workSpaceName');

  constructor(

    @Inject(DOCUMENT) private document: any,
    private apiService: ApiService,
    private commonService: CommonService,
    private router: Router,
    private http: HttpClient,
    private location: Location,
    private authService: AuthenticationService,
    private authFackservice: AuthfakeauthenticationService,
    private activatedRoute: ActivatedRoute,
    public languageService: LanguageService,
    public cookiesService: CookieService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.authFackservice.currentUserValue;
  }

  @Output() mobileMenuButtonClicked = new EventEmitter();
  @Output() settingsButtonClicked = new EventEmitter();

  currentUser;

  ngOnInit(): void {
    this.fetchData();
    this.element = document.documentElement;
    this.configData = {
      suppressScrollX: true,
      wheelSpeed: 0.3
    };

    this.cookieValue = this.cookiesService.get('lang');
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset = 'assets/images/flags/us.jpg';
      }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }

    // Lấy tiêu đề khi component khởi tạo
    this.setPageTitle(this.activatedRoute);

    // Lắng nghe sự kiện NavigationEnd để cập nhật tiêu đề khi điều hướng
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.setPageTitle(this.activatedRoute);
    });
  }

  /**
   * Lấy tiêu đề từ route hiện tại
   */
  private setPageTitle(route: ActivatedRoute) {
    let activeRoute = route.firstChild;
    while (activeRoute?.firstChild) {
      activeRoute = activeRoute.firstChild;
    }
    this.pageTitle = activeRoute?.snapshot.data['title'] || 'Quản lý cửa hàng';
  }

  /**
   * Go back to the previous page
   */
  goBack(event: any) {
    event.preventDefault();
    // this.location.back();
    const currentHash = this.location['_platformLocation']['location']['hash'];
    switch (true) {
      case currentHash.includes('/detail-shop'):
        this.router.navigate(['viettel/list-shop']);
        break;
      case currentHash.includes('/create-device'):
        this.router.navigate(['viettel/device-manager']);
        break;
      case currentHash.includes('/device-detail'):
        this.router.navigate(['viettel/device-manager']);
        break;
      case currentHash.includes('/list-shop'):
        this.router.navigate(['viettel/menu']);
        break;
      case currentHash.includes('/device-manager'):
        this.router.navigate(['viettel/menu']);
        break;
      case currentHash.includes('/account'):
        this.router.navigate(['viettel/menu']);
        break;
      case currentHash.includes('/employee-manager'):
        this.router.navigate(['viettel/menu']);
        break;
      case currentHash.includes('/viettel/dashboard'):
        this.router.navigate(['account/workspace-mobile']);
        break;
      case currentHash.includes('/viettel/address'):
        this.router.navigate(['viettel/payment']);
        break;
      case currentHash.includes('viettel/orders'):
        this.router.navigate(['viettel/menu']);
        break;
      case currentHash.includes('viettel/track-journer'):
        this.router.navigate(['viettel/orders']);
        break;
      case currentHash.includes('viettel/page-cancel'):
        this.router.navigate(['viettel/orders']);
        break;
      case currentHash.includes('viettel/order-detail'):
        this.router.navigate(['viettel/orders']);
        break;
      default:
        this.router.navigate(['viettel/dashboard']);
        break;
    }
  }


  editProfile() {
    this.router.navigate(['viettel/edit-profile']);
  }

  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  getWorkspaceIdByCode(workspaceCode: string): string {
    const workspace = this.listData.find(data => data.code === workspaceCode);
    return workspace ? workspace['id'] : '';
  }

  changeWorkspace(workspaceCode: string, workspaceName: string) {
    this.activeWorkspace = workspaceCode;
    const workspaceId = this.getWorkspaceIdByCode(workspaceCode);
    localStorage.setItem('workspace', workspaceId);
    localStorage.setItem('workSpaceCode', workspaceCode);
    localStorage.setItem('workSpace1', workspaceName);
    window.location.reload();
  }

  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (!document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        this.document.msExitFullscreen();
      }
    }
  }

  workSpace: string = localStorage.getItem('workSpace1');
  workSpaceCode: string = localStorage.getItem('workSpaceCode');
  activeWorkspace: string = this.workSpaceCode;

  setActiveWorkspace(workspaceCode: string) {
    this.activeWorkspace = workspaceCode;
  }


  fetchData(params = '') {
    params = '&order=updated_at&direction=desc';
    if (this.role === 'user') {
      this.apiService.getList('workspaces'
      ).subscribe({
        next: (res) => {
          this.data = res;
          this.listData = res['workspaces'] ? res['workspaces'] : [];
        }
      });
    }
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
    localStorage.setItem('lang', lang);
  }

  logout() {
    if (environment.defaultauth === 'firebase') {
      this.authService.logout();
    } else {
      this.authFackservice.logout();
    }
    localStorage.removeItem('workSpace1');
    localStorage.removeItem('workspace');
    localStorage.removeItem('workSpaceCode');
    this.router.navigate(['/account/login-mobile']);
  }
}
