import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';

import { LayoutComponent } from './layouts/layout/layout.component';
import { LayoutMobileComponent } from './layouts-mobile/layout/layout.component';
import { LayoutCsModule } from './layouts-cs/layouts-cs.module';

const routes: Routes = [
  { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  { path: 'mbbank', loadChildren: () => import('./web-landing-page/web-landing.module').then(m => m.WebLandingModule)},
  { path: '', component: LayoutComponent, loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule), canActivate: [AuthGuard] },
  { path: 'viettel', component: LayoutMobileComponent, loadChildren: () => import('./viettel/viettel.module').then(m => m.ViettelModule), canActivate: [AuthGuard] },
  { path: 'cs', component: LayoutCsModule, loadChildren: () => import('./customer-service/cs.module').then(m => m.CSlModule), canActivate: [AuthGuard] },
  { path: 'pages', loadChildren: () => import('./extrapages/extrapages.module').then(m => m.ExtrapagesModule), canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
