<div class="card">
  <div class="card-body">
    <h5>{{'Danh Sách Thiết Bị' | translate}}</h5>
    <div class="row">
      <!-- <div class="col-3">
                <div class="form-group">
                    <label for="validationCustom01">{{'Chọn ngày ' | translate}}</label>
                    <div>
                        <ng-template #t let-date let-focused="focused">
                            <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                                [class.faded]="isHovered(date) || isInside(date)"
                                (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
                                {{ date.day }}
                            </span>
                        </ng-template>
                        <div class="position-relative">
                            <input type="text" class="form-control date" (click)="hidden=!hidden"
                                name="daterange" autocomplete="off" [(ngModel)]="selected" readonly
                                placeholder="{{'Ngày/ Tháng/ Năm' | translate}}" />
                            <span class="clearDate" *ngIf="selected" (click)="clearDate()">x</span>
                        </div>

                        <ngb-datepicker #dp (select)="onDateSelection($event)" [displayMonths]="2"
                            [dayTemplate]="t" outsideDays="hidden" [hidden]="hidden">
                        </ngb-datepicker>
                    </div>
                </div>
            </div> -->
      <div class="flex-fill row">
        <!-- <div class="col-4">
                        <div class="form-group">
                            <label for="validationCustom01">{{'PAGE.REPORT.IncidentType' | translate}}</label>
                            <ng-select [(ngModel)]="filter.type_id" [clearable]="false">
                                <ng-option [value]="-1">{{'HEADER.NOTIFICATIONS.VIEW_ALL' | translate}}</ng-option>
                                <ng-option [value]="item.id" *ngFor="let item of listType">
                                    {{item.name}}</ng-option>
                            </ng-select>
                            <select class="form-control" [(ngModel)]="filter.type_id">
                                <option value="">{{'PAGE.REPORT.SelectIncidentType' | translate}}</option>
                                <option [ngValue]="item.id" *ngFor="let item of listType">{{item.name}}</option>
                            </select>
                        </div>
                    </div> -->
        <!-- <div class="col-4">
                        <div class="form-group">
                            <label for="validationCustom01">{{'PAGE.REPORT.Location' | translate}}</label>
                            <ng-select [(ngModel)]="filter.location_id" [clearable]="false">
                                <ng-option [value]="-1">{{'HEADER.NOTIFICATIONS.VIEW_ALL' | translate}}</ng-option>
                                <ng-option [value]="item.id" *ngFor="let item of location$|async">
                                    {{item.name}}</ng-option>
                                <ng-option [value]="0"> {{'PAGE.REPORT.other' | translate}}</ng-option>
                            </ng-select>
                        </div>
                    </div> -->
        <div class="col-3">
          <div class="form-group">
            <label for="validationCustom01">{{'Họ tên' | translate}}</label>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="filter.phone"
              placeholder="{{'Nhập họ tên' | translate}}"
            />
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <label for="validationCustom01"
              >{{'Số diện thoại' | translate}}</label
            >
            <input
              type="text"
              class="form-control"
              [(ngModel)]="filter.name"
              placeholder="{{'Nhập số diện thoại' | translate}}"
            />
          </div>
        </div>
        <div class="col-4">
          <div style="min-width: 200px; padding-left: 15px;">
            <div class="form-group">
              <label for="validationCustom01" class="d-block">&nbsp;</label>
              <button class="btn btn-primary btnall" (click)="submitFilter()">
                {{'PAGE.REPORT.Search' | translate}}
              </button>
              <button
                class="btn btn-dark ml-2"
                *ngIf="searching"
                (click)="clearFilter()"
              >
                {{'FORM.Clear' | translate}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="d-flex justify-content-end">
      <div *ngIf="role ==='customer service' || role ==='admin'">
        <button class="btn btn-success mb-2" (click)="openModal(contentAdd)">
          <i class="mdi mdi-plus mr-2"></i> {{'TABLE.AddMore' | translate}}
        </button>
      </div>
    </div> -->
    <div class="table-responsive">
      <table
        id="basic-datatable"
        class="table table-bordered dt-responsive nowrap no-footer dtr-inline"
      >
        <thead>
          <tr class="bg-table">
            <th>#</th>

            <th *ngIf="role ==='admin'">{{'Số điện thoại' | translate}}</th>
            <th *ngIf="role ==='admin'">{{'Họ tên' | translate}}</th>
            <th>{{'Số Seri' | translate}}</th>
            <th>{{'Mã model' | translate}}</th>
            <!-- <th>{{'Trạng thái' | translate}}</th> -->
            <th>{{'Phiên bản' | translate}}</th>
            <th>{{'Ngày tạo' | translate}}</th>
            <th>{{'Ngày cập nhật' | translate}}</th>
            <th width="100" class="table-column">{{'Quản lý' | translate}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of listData;let i=index">
            <td>{{pageSize*(pageIndex-1) +i+1}}</td>

            <td *ngIf="role ==='admin'">{{data.phone}}</td>
            <td *ngIf="role ==='admin'">{{data.full_name}}</td>
            <td>{{data.serial_number}}</td>
            <td>{{data.model}}</td>
            <!-- <td>
              <span
                class="font-size-12 badge badge-danger"
                *ngIf="data.status == '0'"
                >{{'Ngoại tuyến' | translate}}</span
              >

              <span
                class="font-size-12 badge badge-success"
                *ngIf="data.status == '1'"
                >{{'Trực tuyến' | translate}}</span
              >
            </td> -->

            <td>
              {{data.version}}
            </td>
            <td>{{data.created_at | date:'dd/MM/yyyy HH:mm'}}</td>
            <td>{{data.device_updated_at | date:'dd/MM/yyyy HH:mm'}}</td>
            <td class="py-2 table-column">
              <div class="d-flex" style="gap: 5px;">
                <a
                  class="font-size-18 text-danger"
                  ngbTooltip="{{'FORM.Delete' | translate}}"
                  placement="bottom-left"
                  (click)="onDelete(data.id)"
                >
                  <i class="mdi mdi-delete"></i>
                  <!-- {{'FORM.Delete' | translate}} -->
                </a>
                <a
                  *ngIf="data.status_link === null; else showQr"
                  class="font-size-18 text-success"
                  ngbTooltip="{{'Liên kết' | translate}}"
                  placement="left"
                  (click)="onLink(data, contentLink)"
                >
                  <i class="ri-links-line"></i>
                </a>
                <ng-template #showQr>
                  <a
                    (click)="onEdit(data, contentQR)"
                    class="font-size-18 text-primary"
                    ngbTooltip="{{'Chi tiết' | translate}}"
                  >
                    <i class="mdi mdi-eye"></i>
                  </a>
                </ng-template>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row justify-content-md-between align-items-md-center mt-2">
      <div class="col-sm-12 col-md-5">
        <div
          class="dataTables_info mb-2"
          id="tickets-table_info"
          role="status"
          aria-live="polite"
        >
          {{'TABLE.Showing' | translate}} {{pageSize*pageIndex - pageSize + 1}}
          {{'TABLE.to' | translate}} {{pageSize*pageIndex}} {{'TABLE.of' |
          translate}} {{totalCount}} {{'TABLE.entries' | translate}}
        </div>
      </div>
      <!-- Pagination -->
      <div
        class="col-sm-12 col-md-5 d-flex align-items-center justify-content-end"
      >
        <div class="text-md-right float-md-right pagination-rounded">
          <ngb-pagination
          [collectionSize]="totalCount"
          [(page)]="pageIndex"
          [pageSize]="pageSize"
          [maxSize]="5"
          [ellipses]="true"
          (pageChange)="fetchData()"
        >
        </ngb-pagination>
        </div>
        <div class="dataTables_length ml-3" id="tickets-table_length">
          <label class="d-inline-flex align-items-center mb-0">
            {{'TABLE.Show' | translate}}
            <select
              name="tickets-table_length"
              aria-controls="tickets-table"
              name="pageSize"
              [(ngModel)]="pageSize"
              (ngModelChange)="pageIndex = 1;fetchData()"
              class="custom-select custom-select-sm form-control form-control-sm mx-2"
            >
              <option [ngValue]="10">10</option>
              <option [ngValue]="25">25</option>
              <option [ngValue]="50">50</option>
              <option [ngValue]="100">100</option>
            </select>
            {{'TABLE.entries' | translate}}
          </label>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content role="document" let-modal="close">
  <div class="modal-header">
    <h5 class="modal-title">
      {{(formData.value.id ? "FORM.Edit" : "FORM.AddNew") | translate}}
    </h5>
    <!-- <div *ngIf="makeForm('password').hasError('required')" class="error-message">
            Mật khẩu không được bỏ trống.
        </div> -->
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3">
    <div class="text-left">
      <form (ngSubmit)="saveData()" [formGroup]="formData">
        <div class="row">
          <!-- <div class="form-group row col-md-6">
                        <label class="col-md-2 col-form-label required_label">{{'Imei' | translate}}</label>
                        <div class="col-md-10">
                            <input type="text" class="form-control" formControlName="imei"
                                [ngClass]="{'is-invalid': submitted && form.imei.errors}" />
                          
                        </div>
                    </div> -->
          <!-- <div class="form-group row col-md-12" *ngIf="!formData.value.id"> -->

          <div class="form-group row col-md-12">
            <label class="col-md-2 col-form-label required_label"
              >{{'Serial' | translate}}</label
            >
            <div class="col-md-10">
              <input
                type="text"
                class="form-control"
                formControlName="serial_number"
                [ngClass]="{'is-invalid': submitted && form.serial_number.errors}"
              />
            </div>
          </div>
          <div class="form-group row col-md-12">
            <label class="col-md col-form-label required_label"
              >{{'Model' | translate}}</label
            >
            <div class="col-md-10">
              <select
                class="form-select"
                aria-label="Default select example"
                class="form-control"
                formControlName="model"
                [ngClass]="{'is-invalid': submitted && form.model.errors}"
              >
                <option value="{{item}}" *ngFor="let item of listModel"
                  >{{item}}</option
                >
              </select>
              <!-- <input type="text" class="form-control" formControlName="model"
                                [ngClass]="{'is-invalid': submitted && form.model.errors}" />
                           -->
            </div>
          </div>

          <div class="form-group row col-md-12">
            <label class="col-md-2 col-form-label required_label"
              >{{'Phiên bản' | translate}}</label
            >
            <div class="col-md-10">
              <select
                class="form-select"
                aria-label="Default select example"
                class="form-control"
                formControlName="version"
                [ngClass]="{'is-invalid': submitted && form.version.errors}"
              >
                <option value="{{item}}" *ngFor="let item of listVersion"
                  >{{item}}</option
                >
              </select>
            </div>
          </div>
          <!-- <div class="form-group row col-md-12">
                        <label class="col-md-2 col-form-label required_label">{{'Trạng thái' | translate}}</label>
                        <div class="col-md-10">
                            <select class="form-select" aria-label="Default select example" class="form-control" formControlName="status"  [ngClass]="{'is-invalid': submitted && form.status.errors}">
                                <option value="0" >Ngoại tuyến</option>
                                <option value="1" >Trực tuyến</option>
                            </select>
                        </div>
                    </div>   -->
        </div>

        <div class="text-center">
          <button
            type="button"
            class="btn btn-secondary btn-lg mr-3"
            style="width: 150px;"
            (click)="modal('close click')"
          >
            {{'Đóng' | translate}}
          </button>
          <button
            type="submit"
            class="btn btn-success btn-lg"
            style="width: 150px;"
          >
            {{'Lưu' | translate}}
          </button>
          <!-- <button type="button" class="btn btn-secondary btn-sm" style="width: 150px"
                    (click)="modal('close click')">{{'FORM.Close' | translate}}</button>
                <button type="button" class="btn btn-primary btn-sm ml-3" style="width: 150px"
                    (click)="updateFormNote()">{{'FORM.Save' | translate}}</button> -->
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #contentLink role="document" let-modal="close">
  <div class="modal-header">
    <h5 class="modal-title">
      Liên kết thiết bị với cửa hàng
    </h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body p-3">
    <div class="text-left">
      <form (ngSubmit)="deviceLink(formData.value)" [formGroup]="formData">
        <div class="row">
          <div class="form-group row col-md-12">
            <label class="col-md-2 col-form-label required_label">Serial</label>
            <div class="col-md-10">
              <input
                disabled
                type="text"
                class="form-control"
                formControlName="serial_number"
                [ngClass]="{'is-invalid': submitted && form.serial_number.errors}"
              />
            </div>
          </div>

          <div class="form-group row col-md-12">
            <label class="col-md-2 col-form-label required_label">Model</label>
            <div class="col-md-10">
              <input
                disabled
                type="text"
                class="form-control"
                formControlName="model"
                [ngClass]="{'is-invalid': submitted && form.model.errors}"
              />
            </div>
          </div>

          <div class="form-group row col-md-12">
            <label class="col-md-2 col-form-label required_label"
              >Cửa hàng</label
            >
            <div class="col-md-10">
              <ng-select
                formControlName="idWs"
                [items]="listWs"
                bindLabel="name"
                bindValue="id"
                placeholder="Chọn cửa hàng"
                [ngClass]="{'is-invalid': submitted && form.idWs.errors}"
              >
                <ng-template ng-option-tmp let-item="item">
                  {{ item.code }} - {{ item.name }} - {{ item.leader }}
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>

        <div class="text-center">
          <button
            type="button"
            class="btn btn-secondary btn-lg mr-3"
            style="width: 150px;"
            (click)="modal('close click')"
          >
            Đóng
          </button>
          <button
            type="submit"
            class="btn btn-success btn-lg"
            style="width: 150px;"
          >
            Lưu
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<ng-template #contentQR role="document" let-modal="close">
  <div class="modal-header">
    <h5 class="modal-title">
      Hiển thị QR
    </h5>
    <!-- <div *ngIf="makeForm('password').hasError('required')" class="error-message">
                Mật khẩu không được bỏ trống.
            </div> -->
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3">
    <div class="text-left">
      <form (ngSubmit)="saveData()" [formGroup]="formData">
        <div class="row">
          <!-- <div class="form-group row col-md-6">
                            <label class="col-md-2 col-form-label required_label">{{'Imei' | translate}}</label>
                            <div class="col-md-10">
                                <input type="text" class="form-control" formControlName="imei"
                                    [ngClass]="{'is-invalid': submitted && form.imei.errors}" />
                              
                            </div>
                        </div> -->
          <!-- <div class="form-group row col-md-12" *ngIf="!formData.value.id"> -->

          <div class="form-group row col-md-12">
            <label class="col-md-2 col-form-label required_label"
              >{{'Serial' | translate}}</label
            >
            <div class="col-md-10">
              <input
                disabled
                type="text"
                class="form-control"
                formControlName="serial_number"
                [ngClass]="{'is-invalid': submitted && form.serial_number.errors}"
              />
            </div>
          </div>
          <div class="form-group row col-md-12">
            <label class="col-md col-form-label required_label"
              >{{'Model' | translate}}</label
            >
            <div class="col-md-10">
              <select
                disabled
                class="form-select"
                aria-label="Default select example"
                class="form-control"
                formControlName="model"
                [ngClass]="{'is-invalid': submitted && form.model.errors}"
              >
                <option value="{{item}}" *ngFor="let item of listModel"
                  >{{item}}</option
                >
              </select>
              <!-- <input type="text" class="form-control" formControlName="model"
                                    [ngClass]="{'is-invalid': submitted && form.model.errors}" />
                               -->
            </div>
          </div>
          <div class="form-group row col-md-12">
            <div class="col-md-12 d-block justify-content-center">
              <div class="d-flex col-md-12 justify-content-center">
                <div class="borderQR m-auto" *ngIf="qrCodeValue">
                  <img
                    src="assets/images/qrcode_border.png"
                    style="width: 330px; height: 330px;"
                    alt=""
                  />
                  <div class="borderGenQR">
                    <div class="m-auto qrcode-pay">
                      <qr-code
                        class="m-auto"
                        value="{{qrCodeValue}}"
                        size="170"
                        errorCorrectionLevel="M"
                        margin="4"
                        margin-left="35"
                      >
                      </qr-code>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="d-flex justify-content-center mt-3"
                *ngIf="flagDownQr"
              >
                <div class="col-12 col-md-3 p-0">
                  <button class="btn col" (click)="downloadQRCode()">
                    <span class="m-auto">Tải xuống mã QR</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="text-center">
          <button
            type="button"
            class="btn btn-secondary btn-lg mr-3"
            style="width: 150px;"
            (click)="modal('close click')"
          >
            {{'Đóng' | translate}}
          </button>
          <!-- <button
            type="submit"
            class="btn btn-success btn-lg"
            style="width: 150px;"
          >
            {{'Lưu' | translate}}
          </button> -->
          <!-- <button type="button" class="btn btn-secondary btn-sm" style="width: 150px"
                        (click)="modal('close click')">{{'FORM.Close' | translate}}</button>
                    <button type="button" class="btn btn-primary btn-sm ml-3" style="width: 150px"
                        (click)="updateFormNote()">{{'FORM.Save' | translate}}</button> -->
        </div>
      </form>
    </div>
  </div>
</ng-template>
<ng-template #contentAdd role="document" let-modal="close">
  <div class="modal-header">
    <h5 class="modal-title">
      Thêm thiết bị cho người dùng
    </h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body p-3">
    <div class="text-left">
      <form (ngSubmit)="deviceAdd(formData.value)" [formGroup]="formData">
        <div class="row">
          <div class="form-group row col-md-12">
            <label class="col-md-2 col-form-label required_label"
              >Người dùng</label
            >
            <div class="col-md-10">
              <ng-select
                formControlName="userId"
                [items]="listUser"
                bindLabel="full_name"
                bindValue="id"
                placeholder="Chọn người dùng"
                [ngClass]="{'is-invalid': submitted && form.userId.errors}"
              >
                <ng-template ng-option-tmp let-item="item">
                  {{ item.full_name }} - {{ item.phone }} - {{ item.address }}
                </ng-template>
              </ng-select>
            </div>
          </div>
          <div class="form-group row col-md-12">
            <label class="col-md-2 col-form-label required_label">Serial</label>
            <div class="col-md-10">
              <input
                type="text"
                class="form-control"
                formControlName="serial_number"
                [ngClass]="{'is-invalid': submitted && form.serial_number.errors}"
              />
            </div>
          </div>

          <div class="form-group row col-md-12">
            <label class="col-md-2 col-form-label required_label">Model</label>
            <div class="col-md-10">
              <input
                type="text"
                class="form-control"
                formControlName="model"
                [ngClass]="{'is-invalid': submitted && form.model.errors}"
              />
            </div>
          </div>
          <!-- <div class="form-group row col-md-12">
            <label class="col-md-2 col-form-label required_label">Version</label>
            <div class="col-md-10">
              <input
                type="text"
                class="form-control"
                formControlName="version"
                [ngClass]="{'is-invalid': submitted && form.version.errors}"
              />
            </div>
          </div> -->
        </div>

        <div class="text-center">
          <button
            type="button"
            class="btn btn-secondary btn-lg mr-3"
            style="width: 150px;"
            (click)="modal('close click')"
          >
            Đóng
          </button>
          <button
            type="submit"
            class="btn btn-success btn-lg"
            style="width: 150px;"
          >
            Lưu
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<ng-template #contentKeyword let-modalHis="close">
  <div class="modal-header">
    <h5 class="modal-title">List keyword of "{{selectedIncident.name}}"</h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modalHis('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3">
    <div class="mb-3" *ngIf="formNewKeyword">
      <h5 class="font-size-14">Add New Keyword</h5>
      <form
        [formGroup]="formNewKeyword"
        (submit)="onSubmitFormKeyword(2)"
        class="d-flex"
      >
        <input
          type="text"
          class="form-control form-control-sm"
          style="width: 250px;"
          formControlName="name"
        />
        <button class="btn btn-primary btn-sm ml-3" style="width: 150px;">
          Add
        </button>
      </form>
    </div>
    <div class="table-responsive">
      <table
        id="basic-datatable"
        class="table table-bordered dt-responsive nowrap no-footer dtr-inline"
      >
        <thead>
          <tr>
            <th width="50">#</th>
            <th>Name</th>
            <!-- <th>Thời gian</th> -->
            <th width="170"></th>
          </tr>
        </thead>
        <tbody *ngFor="let data of dataKeyword;let i=index">
          <tr>
            <td>{{i+1}}</td>
            <td>
              <ng-container
                *ngIf="selectedKeyword?.id == data.id && formEditKeyword; else normal"
              >
                <form
                  [formGroup]="formEditKeyword"
                  (submit)="onSubmitFormKeyword(1)"
                >
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    formControlName="name"
                  />
                </form>
              </ng-container>
              <ng-template #normal>{{data.name}}</ng-template>
            </td>
            <!-- <td>{{data.created_at*1000 | date:'dd/MM/yyyy HH:mm'}}</td> -->
            <td>
              <div class="d-flex" style="gap: 10px;">
                <a
                  class="btn btn-sm btn-success"
                  href="javascript: void(0);"
                  (click)="editKeyword(data,i)"
                >
                  <i class="mdi mdi-account-edit"></i>{{'FORM.Edit' |
                  translate}}</a
                >
                <a
                  class="text-white btn btn-sm btn-danger"
                  (click)="onDeleteKeyword(data.id)"
                  ><i class="mdi mdi-delete"></i>{{'FORM.Delete' |
                  translate}}</a
                >
              </div>
              <!-- <button class="btn" (click)="editKeyword(data,i)">Sửa</button>
                            <button>Xoá</button> -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>
