<div class="card">
  <div class="card-body">
    <div class="mb-0">
      <div class="mb-2">
        <div class="row modal-content border border-dark ml-0 mb-4 p-0">
          <div class="col col-md-12">
            <div class="d-md-flex justify-content-between">
              <div class="col col-md-6 m-0 p-1">
                <div class="card-body rounded-top row pl-0">
                  <div class="col-2">
                    <img
                      src="../../../assets/images/store.png"
                      class="img-fluid"
                      alt="image"
                    />
                  </div>
                  <div class="col-10 p-2">
                    <div class="d-flex">
                      <p class="">#{{data.workspace_code}}</p>
                      <h2 class="ml-4 fs-5 font-weight-bold">
                        {{ data.workspace_name | translate }}
                      </h2>
                      <p
                        class="ml-4"
                        [ngClass]="{'text-danger': data.device_subscription_status === 'Inactive', 'text-success': data.device_subscription_status === 'Active'}"
                      >
                        {{ data.device_subscription_status }}
                      </p>
                    </div>
                    <div class="d-flex">
                      <p class="">
                        Ngày đăng ký: {{ data.activation_date |
                        date:'dd/MM/yyyy'}}
                      </p>
                      <p class="ml-4">Thành viên: {{countMember || 1}}</p>
                    </div>
                    <div class="d-flex">
                      <p class="">
                        {{ data.terminal_bank_acc
                        }}
                      </p>
                      <p class="ml-4">{{data.terminal_bank_acc_name}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div>
              <div class="d-md-flex">
                <div class="col col-md-6 m-0 p-1 pt-0 mt-0">
                  <h2>Thông tin gói dịch vụ & loa</h2>
                  <div class="card-body rounded-top row">
                    <div class="col-10 p-2">
                      <div class="d-flex">
                        <h2 class="fs-5 font-weight-bold">
                          Loại sản phẩm: {{ data.device_name | translate }}
                        </h2>
                      </div>
                      <div class="d-flex">
                        <p class="">
                          Gói dịch vụ: {{ data.original_duration }} tháng
                        </p>
                      </div>
                      <div class="d-flex">
                        <p class="">
                          Số lượng: {{ data.quantity || '1'}}
                        </p>
                        <p class="ml-4">
                          Mã giới thiệu: {{ data.referrer || 'Không có'}}
                        </p>
                      </div>
                      <div class="d-flex">
                        <p class="">
                          Ngày kích hoạt: {{ data.activation_date }}
                        </p>
                        <p class="ml-4">
                          Ngày hết hạn: {{ data.expiration_date }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col col-md-6 px-3 pt-0 mt-0 border-left">
                  <div>
                    <h2>Thông tin giao hàng</h2>
                    <div class="card-body rounded-top row">
                      <button
                        class="btn btn-outline-primary ml-2 mb-2 btn-sm"
                        (click)="onDetailHistory(data, detailOrder)"
                      >
                        Chi tiết giao hàng
                      </button>
                      <div class="col-10 p-2">
                        <div class="d-flex">
                          <p class="font-weight-bold">
                            Trạng thái đơn hàng:
                          </p>
                          <p class="ml-2">{{ data.status }}</p>
                        </div>
                        <div class="d-flex">
                          <p class="font-weight-bold">Địa chỉ giao hàng:</p>
                          <p
                            *ngIf="data.delivery_method == 'shipper'"
                            class="ml-2"
                          >
                            {{ data.address }}
                          </p>
                          <p
                            *ngIf="data.delivery_method == 'direct'"
                            class="ml-2"
                          >
                            {{ data.terminal_address }}
                          </p>
                        </div>
                        <div class="d-flex">
                          <p class="font-weight-bold">Người nhận:</p>
                          <p
                            *ngIf="data.delivery_method == 'shipper'"
                            class="ml-2"
                          >
                            {{ data.customer_name }}
                          </p>
                          <p
                            *ngIf="data.delivery_method == 'direct'"
                            class="ml-2"
                          >
                            {{ data.full_name }}
                          </p>
                        </div>
                        <div class="d-flex">
                          <p class="font-weight-bold">Số điện thoại:</p>
                          <p
                            *ngIf="data.delivery_method == 'shipper'"
                            class="ml-2"
                          >
                            {{ data.phone }}
                          </p>
                          <p
                            *ngIf="data.delivery_method == 'direct'"
                            class="ml-2"
                          >
                            {{ data.user_phone }}
                          </p>
                        </div>
                        <div
                          class="d-flex"
                          *ngIf="data.delivery_method == 'shipper'"
                        >
                          <p class="font-weight-bold">Mã đơn hàng:</p>
                          <p class="ml-2">
                            {{ data.order_code ? data.order_code : 'Chưa có' }}
                          </p>
                        </div>
                        <div class="d-flex" *ngIf="data.oc_reason">
                          <p class="font-weight-bold">Lý do hủy:</p>
                          <p class="ml-2">
                            {{ data.oc_reason }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="">
          <div class="col col-md-12 bg-viettel border rounded-top">
            <span class="text-white" style="font-size: 20px;"
              >Danh sách thiết bị trong đơn hàng</span
            >
          </div>
          <div class="table-responsive">
            <table
              id="basic-datatable"
              class="table table-bordered dt-responsive nowrap no-footer dtr-inline"
            >
              <thead>
                <tr class="text-center bg-table">
                  <th>{{'#' | translate}}</th>
                  <!-- <th>{{'Mã người dùng' | translate}}</th> -->
                  <th>{{'Mã thiết bị' | translate}}</th>
                  <th>{{'Model' | translate}}</th>
                  <th>{{'Tặng thêm' | translate}}</th>
                  <th>{{'Trạng thái' | translate}}</th>
                  <th>{{'Ngày kích hoạt' | translate}}</th>
                  <th>{{'Ngày hết hạn' | translate}}</th>
                  <th>{{'Quản lý' | translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="text-center"
                  *ngFor="let data2 of listDataQR;let i = index"
                >
                  <td>{{pageSize*(pageIndex-1) +i+1}}</td>

                  <td>
                    {{data2.serial_number}}
                  </td>
                  <td>{{data2.model}}</td>
                  <td>{{data2.bonus_months}} tháng</td>
                  <td
                    [ngClass]="{'text-danger': data2.status === 'Inactive', 'text-success': data2.status === 'Active'}"
                  >
                    {{ data2.status }}
                  </td>
                  <td>{{data2.activation_date | date:'dd/MM/yyyy'}}</td>
                  <td>{{data2.expiration_date | date:'dd/MM/yyyy'}}</td>
                  <td>
                    <div class="d-flex justify-content-around">
                      <button
                        class="btn btn-sm btn-outline-primary"
                        (click)="onChange(data2, contentLink)"
                      >
                        Gửi thông báo
                      </button>
                      <button
                        class="btn btn-sm btn-outline-success"
                        (click)="onEdit(data2, contentSearch)
                      "
                      >
                        Tra cứu giao dịch
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="">
          <div class="col col-md-12 bg-viettel border rounded-top">
            <span class="text-white" style="font-size: 20px;"
              >Lịch sử test thông báo</span
            >
          </div>
          <div class="table-responsive">
            <table
              id="basic-datatable"
              class="table table-bordered dt-responsive nowrap no-footer dtr-inline"
            >
              <thead>
                <tr class="text-center bg-table">
                  <th>{{'#' | translate}}</th>
                  <th>{{'Người tác động' | translate}}</th>
                  <th>{{'Mã thiết bị' | translate}}</th>
                  <th>{{'Số tiền' | translate}}</th>
                  <th>{{'Thao tác' | translate}}</th>
                  <th>{{'Trạng thái' | translate}}</th>
                  <th>{{'Thời gian' | translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="text-center"
                  *ngFor="let data2 of listDataLog;let i = index"
                >
                  <td>{{pageSize*(pageIndex-1) +i+1}}</td>
                  <td>{{data2.full_name}}</td>
                  <td>{{data2.serial_number}}</td>
                  <td>{{data2.amount}}</td>
                  <td>{{'Gửi thông báo'}}</td>
                  <td>
                    {{ data2.status === 1 ? 'Thành công' : 'Thất bại' }}
                  </td>
                  <td>{{data2.created_at}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #changeToOnline role="document" let-modal="close">
  <div class="modal-header">
    <h5 class="modal-title">
      {{"Thay đổi luồng giao hàng sang Online" | translate}}
    </h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3">
    <div class="text-left">
      <form (ngSubmit)="onChangeShip()" [formGroup]="formData">
        <div class="row">
          <div class="form-group row col-md-12">
            <label class="col-md-2 col-form-label required_label"
              >{{ 'Tên người nhận' | translate }}</label
            >
            <div class="col-md-10">
              <input
                type="text"
                class="form-control"
                formControlName="recipient_name"
                [ngClass]="{'is-invalid': submitted && form.recipient_name.errors}"
              />
            </div>
          </div>
          <!-- <div class="form-group row col-md-12">
              <label class="col-5 col-form-label required_label"
                >{{'Phương thức' | translate}}</label
              >
              <div class="col-12">
                <select
                  class="form-select"
                  aria-label="Default select example"
                  class="form-control"
                  formControlName="delivery_method"
                  [ngClass]="{'is-invalid': submitted && form.delivery_method.errors}"
                >
                  <option value="{{item.value}}"
                    >{{item.label}}
                  </option>
                </select>
              </div>
            </div> -->
          <div class="form-group row col-md-12">
            <label class="col-md-2 col-form-label required_label"
              >{{ 'Số điện thoại' | translate }}</label
            >
            <div class="col-md-10">
              <input
                type="phone"
                class="form-control"
                formControlName="phone_number"
                [ngClass]="{'is-invalid': submitted && form.phone_number.errors}"
              />
            </div>
          </div>

          <div class="form-group row col-md-12">
            <label class="col-md-2 col-form-label required_label"
              >{{ 'Thành phố/Tỉnh' | translate }}</label
            >
            <div class="col-md-10">
              <ng-select
                [items]="listProvince"
                bindLabel="PROVINCE_NAME"
                (change)="onProvinceChange($event)"
                formControlName="province"
                placeholder="{{ 'Chọn thành phố/tỉnh' | translate }}"
                [ngClass]="{'is-invalid': submitted && form.province.errors}"
              >
              </ng-select>
            </div>
          </div>

          <div class="form-group row col-md-12">
            <label class="col-md-2 col-form-label required_label"
              >{{ 'Quận/Huyện' | translate }}</label
            >
            <div class="col-md-10">
              <ng-select
                [items]="listDistrict"
                bindLabel="DISTRICT_NAME"
                (change)="onDistrictChange($event)"
                formControlName="district"
                placeholder="{{ 'Chọn quận/huyện' | translate }}"
                [ngClass]="{'is-invalid': submitted && form.district.errors}"
              >
              </ng-select>
            </div>
          </div>

          <div class="form-group row col-md-12">
            <label class="col-md-2 col-form-label required_label"
              >{{ 'Phường/Xã' | translate }}</label
            >
            <div class="col-md-10">
              <ng-select
                [items]="listWard"
                bindLabel="WARDS_NAME"
                formControlName="ward"
                placeholder="{{ 'Chọn phường/xã' | translate }}"
                [ngClass]="{'is-invalid': submitted && form.ward.errors}"
              >
              </ng-select>
            </div>
          </div>

          <div class="form-group row col-md-12">
            <label class="col-md-2 col-form-label required_label"
              >{{ 'Địa chỉ cụ thể' | translate }}</label
            >
            <div class="col-md-10">
              <input
                type="text"
                class="form-control"
                formControlName="specific_address"
                [ngClass]="{'is-invalid': submitted && form.specific_address.errors}"
              />
            </div>
          </div>
        </div>

        <div class="text-center">
          <button
            type="button"
            class="btn btn-secondary btn-lg mr-3"
            style="width: 150px;"
            (click)="modal('close click')"
          >
            {{ 'Đóng' | translate }}
          </button>
          <button
            type="submit"
            class="btn btn-success btn-lg"
            style="width: 150px;"
          >
            {{ 'Cập nhật' | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #contentLink role="document" let-modal="close">
  <div class="modal-header">
    <h5 class="modal-title">
      Gửi thông báo tới thiết bị
    </h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3">
    <div class="text-left">
      <form (ngSubmit)="deviceCheck()" [formGroup]="formData">
        <div class="row">
          <div class="form-group row col-md-12">
            <label class="col-md-2 col-form-label required_label"
              >{{'Thiết bị' | translate}}</label
            >
            <div class="col-md-10">
              <input
                disabled
                type="text"
                class="form-control"
                formControlName="serial_number"
                [ngClass]="{'is-invalid': submitted && form.serial_number.errors}"
              />
            </div>
          </div>
          <div class="form-group row col-md-12">
            <label class="col-md-2 col-form-label required_label"
              >{{'Số tiền' |translate}}</label
            >
            <div class="col-md-10">
              <input
                placeholder="Nhập số tiền"
                type="number"
                class="form-control"
                formControlName="serial_number_new"
                [ngClass]="{'is-invalid': submitted && form.serial_number_new.errors}"
              />
            </div>
          </div>
        </div>
        <div class="text-center">
          <button
            type="button"
            class="btn btn-secondary btn-lg mr-3"
            style="width: 150px;"
            (click)="modal('close click')"
          >
            {{'Đóng' | translate}}
          </button>
          <button
            type="submit"
            class="btn btn-success btn-lg"
            style="width: 150px;"
          >
            {{'Gửi thông báo' | translate}}
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #updatecontent role="document" let-modal="close">
  <div class="modal-header">
    <h5 class="modal-title">
      Cập nhật cuộc gọi
    </h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3">
    <div class="text-left">
      <form (ngSubmit)="updateOrder()" [formGroup]="formData">
        <div class="row">
          <div class="form-group row col-md-12">
            <label class="col-5 col-form-label required_label"
              >{{'Hành động' | translate}}</label
            >
            <div class="col-12">
              <select
                class="form-select"
                aria-label="Default select example"
                class="form-control"
                formControlName="typeUpdate"
                [ngClass]="{'is-invalid': submitted && form.typeUpdate.errors}"
              >
                <option value="{{item.value}}" *ngFor="let item of typePhone"
                  >{{item.label}}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row col-md-12">
            <label class="col-md-5 col-form-label required_label"
              >{{'Ghi chú' | translate}}</label
            >
            <div class="col-md-12">
              <textarea
                placeholder="Nhập ghi chú"
                class="form-control"
                formControlName="note"
                [ngClass]="{'is-invalid': submitted && form.note.errors}"
                rows="3"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="text-center">
          <button
            type="button"
            class="btn btn-secondary btn-lg mr-3"
            style="width: 150px;"
            (click)="modal('close click')"
          >
            {{'Đóng' | translate}}
          </button>
          <button
            type="submit"
            class="btn btn-primary btn-lg"
            style="width: 150px;"
          >
            {{'Cập nhật' | translate}}
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #contentSearch role="document" let-modal="close">
  <div class="modal-header">
    <h5 class="modal-title">
      Tra cứu giao dịch
    </h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3">
    <div class="text-left">
      <form [formGroup]="formData">
        <div class="row">
          <div class="form-group row col-md-12">
            <div class="col-md-6">
              <label class="col-md-12 col-form-label required_label"
                >{{'Mã Thiết Bị' | translate}}</label
              >
              <div class="col-md-10 mt-4">
                <input
                  disabled
                  type="text"
                  class="form-control"
                  formControlName="serial_number"
                  [ngClass]="{'is-invalid': submitted && form.serial_number.errors}"
                />
              </div>
            </div>
            <div class="col-md-6">
              <label class="col-md-12 col-form-label required_label"
                >{{'Khoảng thời gian' | translate}}</label
              >
              <div class="col-md-10">
                <div class="form-group col-3">
                  <mat-form-field appearance="fill" class="mt-3">
                    <mat-label>Chọn khoảng ngày </mat-label>
                    <mat-date-range-input
                      [formGroup]="range"
                      [rangePicker]="picker"
                    >
                      <input
                        matStartDate
                        formControlName="start"
                        placeholder="Ngày bắt đầu"
                      />
                      <input
                        matEndDate
                        formControlName="end"
                        placeholder="Ngày kết thúc"
                      />
                    </mat-date-range-input>
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker"
                    ></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                    <mat-error
                      *ngIf="range.controls.start.hasError('matStartDateInvalid')"
                      >Invalid start date</mat-error
                    >
                    <mat-error
                      *ngIf="range.controls.end.hasError('matEndDateInvalid')"
                      >Invalid end date</mat-error
                    >
                  </mat-form-field>
                </div>
                <p class="text-danger font-size-10 font-italic">
                  Chọn khoảng thời gian để tra cứu
                </p>
                <button class="btn btn-success" (click)="getDataTransaction()">
                  Tra cứu
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table
            id="basic-datatable"
            class="table table-bordered dt-responsive nowrap no-footer dtr-inline"
          >
            <thead>
              <tr class="text-center bg-table">
                <th>{{'#' | translate}}</th>
                <th *ngIf="role ==='admin'">{{'Cửa hàng' | translate}}</th>
                <th>{{'Mã giao dịch' | translate}}</th>
                <th>{{'Thiết bị' | translate}}</th>
                <th>{{'Số tài khoản' | translate}}</th>
                <th>{{'Số tiền' | translate}}</th>
                <th>{{'Trạng thái' | translate}}</th>
                <th>{{'Ngày giao dịch' | translate}}</th>
              </tr>
            </thead>
            <tbody *ngFor="let data of dataTransaction;let i=index">
              <tr>
                <td>
                  <a>{{pageSize*(pageIndex-1) +i+1}}</a>
                </td>
                <td *ngIf="role ==='admin'">
                  <a>{{data?.workspace_name}}</a>
                </td>
                <td><a>{{data?.code}}</a></td>
                <td>
                  <a>{{data?.serial_number}}</a>
                </td>
                <td>
                  <a> {{data.account_number}} </a>
                </td>

                <td>
                  <a>
                    {{data?.total | number:'1.0-0'}} <sup><ins>đ</ins></sup>
                  </a>
                </td>

                <td>
                  <span
                    class="font-size-12 badge badge-danger"
                    *ngIf="data.status == '0'"
                    >{{'Từ chối' | translate}}</span
                  >
                  <span
                    class="font-size-12 badge badge-warning"
                    *ngIf="data.status == '2'"
                    >{{'Đang xử lý' | translate}}</span
                  >
                  <span
                    class="font-size-12 badge badge-success"
                    *ngIf="data.status == '1'"
                    >{{'Đã thanh toán' | translate}}</span
                  >
                </td>
                <td>
                  <a>{{data.created_at | date:'dd/MM/yyyy HH:mm'}}</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row justify-content-md-between align-items-md-center mt-2">
          <div class="col-sm-12 col-md-5">
            <div
              class="dataTables_info mb-2"
              id="tickets-table_info"
              role="status"
              aria-live="polite"
            >
              {{'TABLE.Showing' | translate}} {{pageSize*pageIndex - pageSize +
              1}} {{'TABLE.to' | translate}} {{pageSize*pageIndex}} {{'TABLE.of'
              | translate}} {{totalCount}} {{'TABLE.entries' | translate}}
            </div>
          </div>
          <!-- Pagination -->
          <div
            class="col-sm-12 col-md-5 d-flex align-items-center justify-content-end"
          >
            <div class="text-md-right float-md-right pagination-rounded">
              <ngb-pagination
                [collectionSize]="totalCount"
                [(page)]="pageIndex"
                [pageSize]="pageSize"
                [maxSize]="5"
                [ellipses]="true"
                (pageChange)="getDataTransaction()"
              >
              </ngb-pagination>
            </div>
            <div class="dataTables_length ml-3" id="tickets-table_length">
              <label class="d-inline-flex align-items-center mb-0">
                {{'TABLE.Show' | translate}}
                <select
                  name="tickets-table_length"
                  aria-controls="tickets-table"
                  name="pageSize"
                  [(ngModel)]="pageSize"
                  (ngModelChange)="pageIndex = 1;fetchData()"
                  class="custom-select custom-select-sm form-control form-control-sm mx-2"
                >
                  <option [ngValue]="10">10</option>
                  <option [ngValue]="25">25</option>
                  <option [ngValue]="50">50</option>
                  <option [ngValue]="100">100</option>
                </select>
                {{'TABLE.entries' | translate}}
              </label>
            </div>
          </div>
        </div>
        <div class="text-center">
          <button
            type="button"
            class="btn btn-secondary btn-lg mr-3"
            style="width: 150px;"
            (click)="modal('close click')"
          >
            {{'Đóng' | translate}}
          </button>
          <!-- <button
              type="submit"
              class="btn btn-success btn-lg"
              style="width: 150px;"
            >
              {{'Lưu' | translate}}
            </button> -->
          <!-- <button type="button" class="btn btn-secondary btn-sm" style="width: 150px"
                          (click)="modal('close click')">{{'FORM.Close' | translate}}</button>
                      <button type="button" class="btn btn-primary btn-sm ml-3" style="width: 150px"
                          (click)="updateFormNote()">{{'FORM.Save' | translate}}</button> -->
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #contentCancel role="document" let-modal="close">
  <div class="modal-header">
    <h5 class="modal-title">
      Hủy đơn hàng
    </h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3">
    <div class="text-left">
      <form #deleteForm="ngForm" (ngSubmit)="onDelete()" novalidate>
        <div class="row">
          <div class="form-group row col-md-12">
            <label class="col-md-2 col-form-label required_label">
              {{'Lý do hủy' | translate}}
            </label>
            <div class="col-md-10">
              <input
                placeholder="Nhập lý do hủy"
                type="text"
                class="form-control"
                name="reasonDelete"
                [(ngModel)]="reasonDelete"
                required
                #reasonInput="ngModel"
                [ngClass]="{'is-invalid': submitted && reasonInput.invalid}"
              />
            </div>
          </div>
        </div>
        <div class="text-center">
          <button
            type="button"
            class="btn btn-secondary btn-lg mr-3"
            style="width: 150px;"
            (click)="modal('close click')"
          >
            {{'Đóng' | translate}}
          </button>
          <button
            type="submit"
            class="btn btn-danger btn-lg"
            style="width: 150px;"
            [disabled]="!reasonInput.valid"
          >
            {{'Hủy đơn' | translate}}
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #detailOrder role="document" let-modal="close">
  <div class="modal-header">
    <h5 class="modal-title">
      Chi tiết vận chuyển đơn hàng
    </h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="table-responsive">
    <table
      id="basic-datatable"
      class="table table-bordered dt-responsive nowrap no-footer dtr-inline"
    >
      <thead>
        <tr class="text-center bg-table">
          <th>{{'#' | translate}}</th>
          <!-- <th>{{'Mã đơn' | translate}}</th> -->
          <th>{{'Địa điểm' | translate}}</th>
          <th>{{'Trạng thái' | translate}}</th>
          <th>{{'Thời gian' | translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr class="text-center" *ngFor="let data2 of dataHistory;let i = index">
          <td>{{pageSize*(pageIndex-1) +i+1}}</td>
          <!-- <td>{{data2.order_code}}</td> -->
          <td>{{data2.ware_house}}</td>
          <td>{{data2.status}}</td>
          <td>{{data2.created_at}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>

<ng-template #transactionHistory role="document" let-modal="close">
  <div class="modal-header">
    <h5 class="modal-title">
      10 giao dịch gần đây
    </h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="table-responsive">
    <table
      id="basic-datatable"
      class="table table-bordered dt-responsive nowrap no-footer dtr-inline"
    >
      <thead>
        <tr class="text-center bg-table">
          <th>{{'#' | translate}}</th>
          <th *ngIf="role ==='admin'">{{'Cửa hàng' | translate}}</th>
          <th>{{'Mã giao dịch' | translate}}</th>
          <th>{{'Thiết bị' | translate}}</th>
          <th>{{'Số tài khoản' | translate}}</th>
          <th>{{'Số tiền' | translate}}</th>
          <th>{{'Trạng thái' | translate}}</th>
          <th>{{'Ngày giao dịch' | translate}}</th>
        </tr>
      </thead>
      <tbody *ngFor="let data of dataTransaction;let i=index">
        <tr>
          <td>
            <a>{{pageSize*(pageIndex-1) +i+1}}</a>
          </td>
          <td *ngIf="role ==='admin'">
            <a>{{data?.workspace_name}}</a>
          </td>
          <td><a>{{data?.code}}</a></td>
          <td>
            <a>{{data?.serial_number}}</a>
          </td>
          <td>
            <a> {{data.account_number}} </a>
          </td>

          <td>
            <a>
              {{data?.total | number:'1.0-0'}} <sup><ins>đ</ins></sup>
            </a>
          </td>

          <td>
            <span
              class="font-size-12 badge badge-danger"
              *ngIf="data.status == '0'"
              >{{'Từ chối' | translate}}</span
            >
            <span
              class="font-size-12 badge badge-warning"
              *ngIf="data.status == '2'"
              >{{'Đang xử lý' | translate}}</span
            >
            <span
              class="font-size-12 badge badge-success"
              *ngIf="data.status == '1'"
              >{{'Đã thanh toán' | translate}}</span
            >
          </td>
          <td>
            <a>{{data.created_at | date:'dd/MM/yyyy HH:mm'}}</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>
